import "./App.css";
import globalStyle from "./styles/global.style.js";

import { Layout } from "./styles/theme.config.js";
import Navigation from "./components/Navigation/Navigation";
import Hero from "./components/Hero/Hero";
import Products from "./components/Products/Product";
import About from "./components/About/About";
import Artisans from "./components/Artisans/Artisans";
import ImageGallery from "./components/ImageGallery/ImageGallery";
import Sustainability from "./components/Sustainability/Sustainability";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import data from "./data.js";
import ReactGA from "react-ga";
const TRACKING_ID = "UA-210772082-2";
const App = () => {
  ReactGA.initialize(TRACKING_ID);
  globalStyle();
  return (
    <Layout>
      <Navigation logo={data.logo} companyName={data.companyName} />

      <Hero
        background={data.background}
        mainText={data.mainText}
        subText={data.subText}
      />

      <Products
        productSectionTitle={data.productSectionTitle}
        prodArray={data.prodArray}
        buttonContent={data.buttonContent}
      />

      <About data={data.aboutData} />

      <ImageGallery
        id="certifications"
        title={data.certificationsTitle}
        imgArray={data.imgArray}
        isCarousel={true}
      />

      <Artisans data={data.artisansData} />

      {/* <ImageGallery
        id="brands" 
        title={data.brandsTitle} 
        imgArray={data.brandArray} 
        isCarousel={false} 
      /> */}

      <Sustainability data={data.sustainabilityData} />

      <Contact {...data} />

      <Footer
        logo={data.logo}
        companyName={data.companyName}
        prodArray={data.prodArray}
        brandArray={data.brandArray}
      />
    </Layout>
  );
};

export default App;
