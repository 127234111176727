import * as S from "./Products.style.js";
import useAnalyticsEventTracker from "../../utils/tracker";
import { ACTIONS, CATEGORY } from "../../utils/constants.js";
const Products = ({ productSectionTitle, prodArray, buttonContent }) => {
  const gaEventTracker = useAnalyticsEventTracker("Product Section");
  return (
    <S.Container id="products">
      <S.Header>{productSectionTitle}</S.Header>
      <S.Content>
        {prodArray.map((product, key) => (
          <S.Product href={product.link} target="_blank">
            <S.Image src={product.image} />
            <S.Title>{product.title}</S.Title>
            <S.Link
              onClick={() => {
                gaEventTracker(ACTIONS.BUTTON_CLICK, CATEGORY.PRODUCT_CARD);
              }}
              href={product.link}
              target="_blank"
            >
              View products
            </S.Link>
          </S.Product>
        ))}
      </S.Content>
      <S.Button
        onClick={() => {
          gaEventTracker(ACTIONS.BUTTON_CLICK, CATEGORY.SEND_QUERY);
        }}
        href="/#contact"
      >
        {buttonContent}
      </S.Button>
    </S.Container>
  );
};

export default Products;
