import * as S from './Sustainability.style.js';

const Sustainability = ({data}) => {
    return (
        <S.Container>
            <S.Header>{data.title}</S.Header>
            <S.Image src={data.image}/>
            <S.Text>
            At Orient Hometex, we strive to design and create traditional handcrafted textiles with minimal environmental and social impact.We are proud of our exceptional craftsmanship, high quality, and sustainable ways of production. All of our carpets are made in-house at a facility that has been certified by government agencies to ensure that basic production requirements are satisfied throughout the entire process. <br />
            As members of Goodweave, we maintain the norms of the Labor Act / Factories Act for the benefit of our employees, and we make every effort to guarantee that no child labour is employed at any step of our production. <br />
            We maintain the highest quality standards throughout the process, from weaving to packaging. To meet the customer’s requirements, we have a wide range of testing equipment, ensuring high quality at every stage of production<br />
            </S.Text>
        </S.Container>
    )
}

export default Sustainability;