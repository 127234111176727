import { useState } from "react";

import * as Icons from "react-feather";
import * as S from "./Navigation.style.js";
import useAnalyticsEventTracker from "../../utils/tracker";
import { ACTIONS, CATEGORY } from "../../utils/constants.js";
import "./style.css";

const Elements = [
  {
    link: "/#home",
    title: "Home",
  },
  {
    link: "/#products",
    title: "Products",
  },
  {
    link: "/#about",
    title: "About us",
  },
  {
    link: "/#certifications",
    title: "Certifications",
  },
];

const Navigation = ({ logo, companyName }) => {
  const [menu, setMenu] = useState(false);
  const gaEventTracker = useAnalyticsEventTracker("Navigation");
  const ToggleMenu = () => {
    gaEventTracker(ACTIONS.BUTTON_CLICK, CATEGORY.CONTACT);
    setMenu((state) => !state);
  };

  return (
    <>
      {/*Navigation Bar */}
      <S.Nav>
        <S.Container>
          <S.Icon>
            <S.Image src={logo} />
            <S.Header>{companyName}</S.Header>
          </S.Icon>
          <S.MenuButton onClick={ToggleMenu}>
            <Icons.Menu color="#5A606B" />
          </S.MenuButton>
          <S.LinkContainer>
            {Elements.map((value, key) => (
              <S.Link href={value.link}> {value.title} </S.Link>
            ))}

            <S.ContactButton
              href="/#contact"
              onClick={() => {
                gaEventTracker(ACTIONS.BUTTON_CLICK, CATEGORY.CONTACT);
              }}
            >
              Contact us
            </S.ContactButton>
          </S.LinkContainer>
        </S.Container>
      </S.Nav>

      {/*Menu Box for small size screens*/}
      <S.MenuContainer className={menu ? "" : "clear"}>
        <S.Menu className="menuBox">
          {/* <S.Cross onClick = {ToggleMenu}>
            <Icons.XCircle color='#5A606B'/>
          </S.Cross> */}
          {Elements.map((value, key) => (
            <S.MenuLink key={key} href={value.link} onClick={ToggleMenu}>
              {value.title}
            </S.MenuLink>
          ))}
          <S.ContactButton href="/#contact" onClick={ToggleMenu}>
            Contact us
          </S.ContactButton>
        </S.Menu>
      </S.MenuContainer>
    </>
  );
};

export default Navigation;
