import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import * as S from './About.style.js';
import globalStyle from "../../styles/global.style";

const About = ({data}) => {
    globalStyle();

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        adaptiveHeight: true,
      };

    return (
        <S.Container id="about">
            <S.Box>
                <S.Header>About Us</S.Header>
                <S.Text>
                We at Orient Hometex, are expert vendors when it comes to the product quality, uniqueness and style with over 50 years of hands-on manufacturing experience. Our <b>handmade Indian carpets, rugs,</b> bespoke <b>pillows,</b> and other items have gotten love from all around the world.  <br /><br />

                Our manufacturing plants, where we craft such pieces of art, are fully compliant as per the requirement of international standards. Our headquarters and manufacturing plant is spread over 200,000 square feet in the heart of Panipat, just about 100 KMs from New Delhi Airport. <br /><br />

                We work with some of the most well-known retailers, wholesalers, interior decorators, and importers from North America, Europe, Japan, and Australia. Our monthly manufacturing capacity for <b>Hand Tufted</b> is 30,000 square metres, 5,000 square metres for <b>Hand Knotted,</b> 20,000 square metres for <b>Hand Woven,</b> 10,000 square metres for <b>Handloom</b>, 60,000 square metres for <b>Pillows</b>, and 10,000 square metres for <b>Poufs</b>. <br /><br />
                {/* <ul style={{listStyleType : "none"}}>
                <li>Hand Tufted: 30,000 sq meter</li>
                <li>Hand Knotted: 5,000 sq meter</li>
                <li>Hand Woven: 20,000 sq meter</li>
                <li>Handloom: 10,000 sq meter</li>
                <li>Pillows: 60,000 pcs.</li>
                <li>Poufs: 10,000 pcs.</li>
                </ul> */}
                </S.Text>
                <S.Content>
                <Slider {...settings}>
                    {data.map((value, key) => (
                            <S.Image src={value.image}/>
                    ))}
                </Slider>
                </S.Content>
            </S.Box>
        </S.Container>

        // <S.Container id="about">
        //     {data.map((value, key) => (
        //         key % 2 == 0 
        //         ? 
        //         <S.Box>
        //             <S.Content>
        //                 <S.Header>
        //                     {value.title}
        //                 </S.Header>
        //                 <S.Text>
        //                     {value.text}
        //                 </S.Text>
        //             </S.Content>
        //             <S.Content>
        //                 <S.Image src={value.image}/>
        //             </S.Content>
        //         </S.Box>
        //         :
        //         <S.Box>
        //             <S.Content>
        //                 <S.Image src={value.image}/>
        //             </S.Content>
        //             <S.Content>
        //                 <S.Header>
        //                     {value.title}
        //                 </S.Header>
        //                 <S.Text>
        //                     {value.text}
        //                 </S.Text>
        //             </S.Content>
        //         </S.Box>
        //     ))}
        // </S.Container>

    )
}

export default About;