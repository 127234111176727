import * as S from "./Contact.style.js";
import { ToastContainer, toast } from "react-toastify";
import { Button } from "antd";
import "./contact.css";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import Mailgun from "mailgun.js";
import formData from "form-data";
// import { gaEvent } from "../../analytics/index.js";
// import { EVENT, CATEGORY, DESCRIPTION } from "../../analytics/constants";

import useAnalyticsEventTracker from "../../utils/tracker";
import { ACTIONS, CATEGORY } from "../../utils/constants.js";

const mailgun = new Mailgun(formData);
const mg = mailgun.client({
  username: "api",
  key: process.env.REACT_APP_MAILGUN,
});

const Contact = ({ emails }) => {
  const gaEventTracker = useAnalyticsEventTracker("Product Section");
  const [loading, setLoading] = useState(false);
  const [toSend, setToSend] = useState({
    from_name: "",
    company_name: "",
    email: "",
    mobile_number: "",
    message: "",
  });

  const onSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    mg.messages
      .create("mail.sourcewiz.co", {
        from: "Orient Hometex via Sourcewiz <no-reply@sourcewiz.co>",

        to: emails,
        subject: "New Enquiry from Our Website",
        bcc: [
          "mayur@sourcewiz.co",
          "mayur.sonawane@sourcewiz.co",
          "ashmit@sourcewiz.co",
          "aksharaa@sourcewiz.co",
        ],
        html: `<div>
        <h5>Name: ${toSend?.from_name} </h5>
          <h5>Company Name: ${toSend?.company_name} </h5>
            <h5>Email: ${toSend?.email} </h5>
              <h5>Mobile number: ${toSend?.mobile_number} </h5>
               <h5>Message: ${toSend?.message}</h5>
        </div>`,
      })
      .then((msg) => {
        console.log(msg);
        setLoading(false);
        toast.success(
          "Email sent successfully",

          { position: toast.POSITION.BOTTOM_RIGHT }
        );
        // window.gtag("event", "conversion", {
        //   send_to: "AW-10807807330/34gFCMDd9cADEOKayKEo",
        // });

        // gaEvent(EVENT.CLICK, CATEGORY.CONTACT, DESCRIPTION.NAVIGATION_BUTTON);
      }) // logs response data
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });

    // send(
    //   "service_m2bies5",
    //   "template_tszh7rx",
    //   toSend,
    //   "user_rEu8OTgsotEwoItNT94JZ"
    // )
    //   .then((response) => {
    //     console.log("SUCCESS!", response.status, response.text);
    //   })
    //   .catch((err) => {
    //     console.log("FAILED...", err);
    //   });
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  return (
    <S.Container id="contact">
      <S.Content>
        <S.Header>Send us an enquiry</S.Header>

        <S.Text>
          We will get back to address your enquiry within 24 hours.
        </S.Text>

        <S.FormContainer autoComplete="off" method="POST" onSubmit={onSubmit}>
          <S.Row>
            <S.Box>
              <S.InputHeading>Your Name *</S.InputHeading>
              <S.Input
                type="text"
                name="from_name"
                required
                value={toSend.from_name}
                onChange={handleChange}
              />
            </S.Box>
            <S.Box>
              <S.InputHeading>Company *</S.InputHeading>
              <S.Input
                type="text"
                name="company_name"
                required
                value={toSend.company_name}
                onChange={handleChange}
              />
            </S.Box>
          </S.Row>

          <S.Row>
            <S.Box>
              <S.InputHeading>Email ID *</S.InputHeading>
              <S.Input
                type="email"
                name="email"
                required
                value={toSend.email}
                onChange={handleChange}
              />
            </S.Box>
            <S.Box>
              <S.InputHeading>Contact number *</S.InputHeading>
              <S.Input
                type="tel"
                name="mobile_number"
                required
                value={toSend.mobile_number}
                onChange={handleChange}
              />
            </S.Box>
          </S.Row>

          <S.Row>
            <S.Box>
              <S.InputHeading>Message (Optional)</S.InputHeading>
              <S.TextArea
                name="message"
                value={toSend.message}
                onChange={handleChange}
              />
            </S.Box>
          </S.Row>

          <S.Row>
            {loading ? (
              <Button className="loading__btn" loading={loading}>
                Submit query
              </Button>
            ) : (
              <S.Button
                onClick={() => {
                  gaEventTracker(ACTIONS.BUTTON_CLICK, CATEGORY.PRODUCT_CARD);
                }}
                as="button"
                type="submit"
                value="send"
              >
                Submit query
              </S.Button>
            )}
          </S.Row>
        </S.FormContainer>
      </S.Content>
      <ToastContainer />
    </S.Container>
  );
};

export default Contact;
