export const ACTIONS = {
  BUTTON_CLICK: "Button Click",
};

export const CATEGORY = {
  CONTACT: "Contact Us",
  SEND_QUERY: "Send Query",
  SENT_QUERY: "Query Sent",
  PRODUCT_CARD: "Product Card",
};
