import Logo from './assets/logo.png';
import Background from './assets/Hero_image.png';
import Product_1 from './assets/Product_1.png';
import Product_2 from './assets/Product_2.png';
import Product_3 from './assets/Product_3.png';
import Product_4 from './assets/Product_4.png';
import Product_5 from './assets/Product_5.png';
import Product_6 from './assets/Product_6.png';
import Product_7 from './assets/Product_7.png';
import Product_8 from './assets/Product_8.png';

import About_1 from './assets/About_1.png';
import About_2 from './assets/About_2.png';
import About_3 from './assets/About_3.png';
import About_4 from './assets/About_4.png';
import About_5 from './assets/About_5.png';

import Certification_1 from './assets/Certification_1.png';
import Certification_2 from './assets/Certification_2.png';
import Certification_3 from './assets/Certification_3.png';
import Certification_4 from './assets/Certification_4.png';
import Certification_5 from './assets/Certification_5.png';
import Certification_6 from './assets/Certification_6.png';
import Certification_7 from './assets/Certification_7.png';
import Certification_8 from './assets/Certification_8.png';
import Certification_9 from './assets/Certification_9.png';
import Certification_10 from './assets/Certification_10.png';

import artisans from './assets/artisans.png'
import sustainability from './assets/sustainability.png';

const data = {
    'companyName': "Orient Hometex",
    "mainText": "Redefining luxury & bringing magic to homes",
    "subText": "Today, Orient Hometex is fast emerging as one of the leading manufacturers & exporters of floor coverings.  With 50+ years of hands-on experience in weaving handmade home textile products, we have gotten love and praise from North America, Europe, Japan, and Australia",
    'productSectionTitle': "Our Products",
    'brandsTitle': "Brands we work with",
    'certificationsTitle': "Compliances and Certifications",
    'buttonContent' : 'Send an enquiry',
    'logo': Logo,
    'background': Background,
    'prodLinkArray': [
        {
            title: 'Rugs',
            link: '/#',
        },
        {
            title: 'Pillows',
            link: 'https://catalogue.sourcewiz.co/l9jfsn0',
        },
        {
            title: 'Poufs',
            link: 'https://catalogue.sourcewiz.co/hyadwz6',
        },
        {
            title: 'Wall hangers',
            link: '/#',
        },
    ],

    'brandArray': [
        {
            title: 'Company 1',
            link: '/#',
            image: Background,
        },
        {
            title: 'Company 2',
            link: '/#',
            image: Background,
        },
        {
            title: 'Company 3',
            link: '/#',
            image: Background,
        },
        {
            title: 'Company 4',
            link: '/#',
            image: Background,
        },
        {
            title: 'Company 5',
            link: '/#',
            image: Background,
        },
        {
            title: 'Company 6',
            link: '/#',
            image: Background,
        },
        {
            title: 'Company 7',
            link: '/#',
            image: Background,
        },
        {
            title: 'Company 8',
            link: '/#',
            image: Background,
        },
    ],
    'prodArray': [
        {   title: 'Handwoven / Flatweave Rugs',
            image: Product_1,
            link: 'https://catalogue.sourcewiz.co/m0tqtrp',
        },
        {
            title: 'Hand Tufted Rugs',
            image: Product_2,
            link: 'https://catalogue.sourcewiz.co/i6tlwwx',
        },
        {
            title: 'Hand Knotted Rugs',
            image: Product_3,
            link: 'https://catalogue.sourcewiz.co/to041h3',
        },
        {
            title: 'Indoor / Outdoor Rugs',
            image: Product_4,
            link: 'https://catalogue.sourcewiz.co/qbktdf7',
        },
        {
            title: 'Jute / Natural Fiber Rugs',
            image: Product_5,
            link: 'https://catalogue.sourcewiz.co/ymhlrpk',
        },
        {
            title: 'Pillows',
            image: Product_6,
            link: 'https://catalogue.sourcewiz.co/l9jfsn0',
        },
        {
            title: 'Poufs',
            image: Product_7,
            link: 'https://catalogue.sourcewiz.co/hyadwz6',
        },
        {
            title: 'Throws',
            image: Product_8,
            link: 'https://catalogue.sourcewiz.co/z0t4z6i',
        },
    ],
    'imgArray': [
        {
            image: Certification_1,
        },
        {
            image: Certification_2,
        },
        {
            image: Certification_3,
        },
        {
            image: Certification_4,
        },
        {
            image: Certification_5,
        },
        {
            image: Certification_6,
        },
        {
            image: Certification_7,
        },
        {
            image: Certification_8,
        },
        {
            image: Certification_9,
        },
        {
            image: Certification_10,
        },
    ],

    'aboutData' : [
        {
            title : 'About us',
            text : "Orient Hometex was founded by entrepreneurs Mr. Imran Ansari & Mr. Firoz Ansari in the year 2008. Manufacturing plant of the company is spread over 128000 square feet in Panipat, just about 98 km away from New Delhi. Our core strength lies in Product Development & Design, supported by in-house facilities like dyeing, weaving, finishing & packaging that enables us to meet the requirements of some of the most demanding customers in the world. We have a dedicated and quite knowledgeable product design team that monitors the latest market trends to create designs with mood boards and samples to provide additional value to our customers. We strive to become partners in the progress of our customers and understand their needs to deliver the latest design & product development inputs to make our journey a success. We believe in constant innovation, world class customer service and an insatiable thirst for excellence in all aspect of the business.",
            image: About_1,
        },
        {
            title : 'Vision',
            text : 'Our vision is to be leaders in the line of manufacturing and bringing to market the finest rugs in the world, improving the lifestyles and livelihoods of stakeholders and employees alike. We intend on delivering the best value to our customers through our products.',
            image: About_2,
        },
        {
            title : 'Values',
            text : 'Ethics & Integrity Professionalism & Excellence Teamwork Trust & Loyalty Care-Sensitivity-Responsibility Respect & Dignity',
            image: About_4,
        },
        {
            title : 'Why choose us?',
            text : 'Best vendor when it comes to the product quality, uniqueness and style for all categories of rugs. Over 10 Years of hands-on manufacturing experience. Proved ourselves as a Trusted business Partner On time quick response. The success behind quick response and fast delivery is our composite unit having production stages of weaving, dyeing, stitching, finishing & packing to enable us to process multiple orders simultaneously as per requirement.',
            image: About_5,
        },
    ],

    'sustainabilityData' : {
        title : 'Sustainability is the way of life, the way of our manufacturing',
        text : '',
        image : sustainability,
    },

    'artisansData' : {
        title : "The artisans are the masters behind creating our world class products",
        text : "",
        image : artisans,
    },

    
    "emails" : ["monish@orient-hometex.com"],
   



}

export default data;

