import { styled } from './../../styles/theme.config.js';

export const Container = styled('div', {
    display        : 'flex',
    justifyContent : 'center',
    flexDirection  : 'column',
    alignItems     : 'center',
    width          : '100vw',
    zIndex         :  -1,
    margin         : '4em 0',
})

export const Content = styled('div', {
    display : 'flex',
    flexDirection  : 'column',
    justifyContent : 'center',
    width : '80%',
})

export const Header = styled('h2', {
    paddingTop : '0em',
})

export const Text = styled('p', {
    padding     : '0.5em  0',
    color       : 'rgba(0, 0, 0, 0.7)',
    lineHeight  : '32px',
    '@media only screen and (max-width : 750px)' :{
      textAlign : 'justify',
      padding   : '1em 0',
    },
})

export const FormContainer = styled('form', {
    display        : 'flex',
    justifyContent : 'center',
    flexDirection  : 'column',
    padding : '1em 0',
    '@media only screen and (max-width : 600px)' : {

    }
})


export const Row = styled('div', {
    display : 'flex',
    padding : '1em 0',
    '@media only screen and (max-width : 600px)' : {
        flexDirection : 'column',
    }
})

export const Box = styled('div', {
    display : 'flex',
    flexDirection : 'column',
    width : '100%'
})

export const Input = styled('input', {
    padding      : 10,
    outline      : 'none',
    border       : '1px solid #CDCFCE',
    fontSize     : '$2',
    width        : '90%',
    margin       : '1em 0',
    borderRadius : 3,
    '@media only screen and (max-width : 600px)' : {
        width    : '80vw',
    }
})

export const TextArea = styled('textarea', {
    border       : '1px solid #CDCFCE',
    padding      : 10,
    outline      : 'none',
    fontSize     : '$2',
    width        : '95%',
    margin       : '1em 0',
    borderRadius : 3,
    resize       : 'none',
    '@media only screen and (max-width : 600px)' : {
        width    : '80vw',
    }
})

export const InputHeading = styled('label',{
    fontWeight : 'bold',
})

export const Button = styled('a', {
    display        : 'flex',
    justifyContent : 'center',
    alignItems     : 'center',
    background     : '$button',
    fontSize       : '$2',
    fontWeight     : '600',
    height         : '3em',
    padding        : '0.5em 5em',
    borderRadius   : '4px',
    border         : 'none',
    color          : '$white',
    textDecoration : 'none',
    maxWidth : '18em',
    '&:hover' : {
        color   : '$white',
    },
    '@media only screen and (max-width : 600px)' : {
        maxWidth : '10em',
        padding : '0'
    }
  })

