import * as S from './Artisans.style.js'

const Artisans = ({data}) => {
    return (
        <S.Container>
            <S.Header>{data.title}</S.Header>
            <S.Image src={data.image} alt={data.title}/>
            <S.Text>
            Carpet weaving is a centuries-old Indian tradition, with the carpet business flourishing in India from the 16th century. Our Panipat based artisans are traditionally trained in the craft of hand tufted carpets, hand loom carpets, hand knotted carpets, hand woven carpets, pillows, and poufs. <br />
            The works of our artisans show an unparalleled level of attention to details and expertise. They apply years of experience and talent in design, textures, and colours to give classic replicas a fresh look and to create unique works of art! <br />
            While the classical interpretations are a journey back in time, the contemporary designs capture the imagination of the fashion-forward.  With their unrivalled talents, our artisans create our comprehensive range of designs and color combinations to ensures that there is something for everyone! <br />
            </S.Text>
        </S.Container>
    )
}

export default Artisans;