import React from "react";
import ReactGA from "react-ga";

const useAnalyticsEventTracker = (category = "Initial") => {
  const eventTracker = (action = "test action", label = "Event Triggered") => {
    ReactGA.event({ category, label: action, action: label });
  };
  return eventTracker;
};
export default useAnalyticsEventTracker;
