import { styled } from '../../styles/theme.config.js';

export const Container = styled('div', {
    display        : 'flex',
    justifyContent : 'center',
    alignItems     : 'center',
    flexDirection  : 'column',
    width          : '100vw',
    zIndex         :  -1,
    marginTop   : '4em',
    marginBottom   : '6em',
})

export const Header = styled('h2', {
    textAlign : 'center',
    '@media only screen and (max-width : 750px)' :{
        padding   : '1em 1em',
    },
})

export const Image = styled('img', {
    width : '150px',
    margin : '2em',
})

export const Text = styled('p', {
    width  : '85vw',
    textAlign : 'left',
    color       : 'rgba(0, 0, 0, 0.7)',
    lineHeight  : '32px',
    '@media only screen and (max-width : 750px)' :{
        padding   : '1em 1em',
        width  : '80vw',
    },
})